import React, { Fragment, useEffect, useState } from "react";
import * as S from "./styles";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import {
  GameType,
  GroupDetailType,
  RulesGroupType,
  RecordGroup,
  ParsedDataType,
  RulesType,
  TeamType,
  TiebreakersTeamsType,
  TiebreakersSectionsType,
  OutletContextType,
  CompetitionDefineType,
  CompetitionGroupsDefineType,
} from "types";
import { GroupBar } from "components/GroupBar";
import { Game } from "components/Game";
import { Cell } from "components/Cell";
import { Button } from "components/Button";
import { toast } from "react-toastify";
import { useData } from "DataContext";
import {
  GROUP_COLUMNS,
  MAX_NUM_TEAMS_FOR_DRAW,
  MIN_NUM_TEAMS_FOR_NUMERATION,
} from "parameters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faCircleXmark, faRepeat } from "@fortawesome/free-solid-svg-icons";

export const GroupDetail: React.FC<GroupDetailType> = ({
  groupData,
  competition,
  qualification: defaultQualification = [],
  rules: defaultRules,
}) => {
  const [games, setGames] = useState<GameType[]>([]);
  const [rules, setRules] = useState<RulesGroupType[]>(defaultRules);
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpenRules, setIsOpenRules] = useState(false);
  const { data } = useData();
  const { setIsOpenSidebar } = useOutletContext<OutletContextType>();
  const [groupTeams, setGroupTeams] = useState(groupData.teams || []);
  const [groupGames, setGroupGames] = useState(groupData.games);
  const [qualification, setQualification] = useState(defaultQualification);
  const [terceros, setTerceros] = useState([]);

  const { REACT_APP_BASE_URL, NODE_ENV } = process.env;
  const DEVELOP = NODE_ENV === "development";
  const QUALI_LOG = DEVELOP && false;

  const RULES: RulesType = data.RULES;
  const ALL_TEAMS: TeamType = data.TEAMS;
  const ALL_GAMES: GameType[] = data.GAMES;
  const tiebreakFairPlay: string[] = [];
  const tiebreakersTeams: TiebreakersTeamsType[] = [];
  const tiebreakersSections: TiebreakersSectionsType[] = [];

  const pathname = location.pathname.split("/");
  pathname.shift();
  const [competitionId, groupId] = pathname;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const rawDataA = searchParams.get("a");
    const rawDataB = searchParams.get("b");

    const rawData = rawDataA || rawDataB;
    if (rawData) {
      const data: number[] = rawData.split(",").map(Number);

      const parsedData: ParsedDataType = {};
      const dataAGameLength = 2;
      const dataBGameLength = 8;
      const dataGameLength = rawDataA ? dataAGameLength : dataBGameLength;
      for (let i = 0; i < data.length; i += dataGameLength + 1) {
        if (data[i + dataGameLength] === undefined) break; // si el último partido tiene la data incompleta se ignora
        let key = data[i];
        let values = data.slice(i + 1, i + 1 + dataGameLength);
        parsedData[key] = values;
      }

      const groupDataGamesCopy: GameType[] = structuredClone(groupGames);
      const parsedGames: GameType[] = groupDataGamesCopy.map((game) => {
        game.yellowCards1 = -1;
        game.yellowCards2 = -1;
        game.doubleYellowCards1 = -1;
        game.doubleYellowCards2 = -1;
        game.redCards1 = -1;
        game.redCards2 = -1;
        if (parsedData[game.id] === undefined) {
          game.played = false;
          game.score1 = -1;
          game.score2 = -1;
        } else {
          game.played = true;
          game.score1 = parsedData[game.id][0];
          game.score2 = parsedData[game.id][1];
          if (rawDataB) {
            game.yellowCards1 = parsedData[game.id][2] || -1;
            game.yellowCards2 = parsedData[game.id][3] || -1;
            game.doubleYellowCards1 = parsedData[game.id][4] || -1;
            game.doubleYellowCards2 = parsedData[game.id][5] || -1;
            game.redCards1 = parsedData[game.id][6] || -1;
            game.redCards2 = parsedData[game.id][7] || -1;
          }
        }
        return game;
      });
      return setGames(parsedGames);
    }

    const storageData = localStorage.getItem(location.pathname);
    if (storageData) {
      const storageGames = JSON.parse(storageData);
      return setGames(storageGames);
    }

    setGames(groupGames);
  }, [groupGames, location]);

  useEffect(() => {
    if (groupId === "3") {
      const COMPETITION: CompetitionDefineType = data.COMPETITIONS_DEFINE.find(
        (cd: CompetitionDefineType) => cd.id === competitionId
      );

      const RULES3ROS = data.COMPETITIONS_DEFINE.find(
        (cd: CompetitionDefineType) => cd.id === "euro_2024"
      ).rules3ros;
      setRules(RULES3ROS);

      const QUALIFICATION_3ROS = data.COMPETITIONS_DEFINE.find(
        (cd: CompetitionDefineType) => cd.id === "euro_2024"
      ).qualification3ros;
      setQualification(QUALIFICATION_3ROS || []);

      const getGamesByGroup = (groupId: string): GameType[] => {
        const storageData = localStorage.getItem(
          `/${competitionId}/${groupId}`
        );
        if (storageData) return JSON.parse(storageData);
        return ALL_GAMES.filter(
          (g) => g.competitionId === competitionId && g.group === groupId
        );
      };

      const groupsData: any = [];
      COMPETITION.groups
        .filter((g) => g.id !== "3")
        .forEach((g: CompetitionGroupsDefineType) => {
          const gamesByGroup = getGamesByGroup(g.id);
          const rec = groupQualification(g.teams, defaultRules, gamesByGroup);
          groupsData.push(rec);
        });

      const tercerosAux = groupsData
        .map((gd: any) => gd[2])
        .map((g: any) => g.team);

      setTerceros(tercerosAux);
      setGroupGames(ALL_GAMES.filter((g) => g.competitionId === competitionId));
    } else {
      setQualification(defaultQualification);
      setRules(defaultRules);
      setGroupTeams(groupData.teams);
      setGroupGames(groupData.games);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location,
    groupData,
    ALL_GAMES,
    RULES,
    data,
    defaultRules,
    competitionId,
    groupId,
    defaultQualification,
  ]);

  if (!groupData) return <div>Grupo no encontrado</div>;

  //region groupCalculation
  const groupCalculation = (teams: string[], games: GameType[]) => {
    const result: RecordGroup[] = [];

    teams.forEach((team, index) => {
      const JG = getJG(team, games);
      const JE = getJE(team, games);
      const JP = getJP(team, games);
      const JJ = JG + JE + JP;
      const GF = getGF(team, games);
      const GC = getGC(team, games);
      const DG = GF - GC;
      const PTS = 3 * JG + 1 * JE;
      const yellowCards = getYellowCards(team, games);
      const doubleYellowCards = getDoubleYellowCards(team, games);
      const redCards = getRedCards(team, games);
      const teamRecord: RecordGroup = {
        team,
        JJ,
        JG,
        JE,
        JP,
        GF,
        GC,
        DG,
        PTS,
        yellowCards,
        doubleYellowCards,
        redCards,
      };
      result[index] = teamRecord;
    });

    return result;
  };

  const getJG = (team: string, games: GameType[]) => {
    let JG = 0;
    games.forEach((game) => {
      const { team1, score1, team2, score2 } = game;
      if (team1 === team && score1 > score2) JG++;
      if (team2 === team && score1 < score2) JG++;
    });
    return JG;
  };

  const getJE = (team: string, games: GameType[]) => {
    let JE = 0;
    games.forEach((game) => {
      const { team1, score1, team2, score2 } = game;
      if (team1 === team && score1 === score2) JE++;
      if (team2 === team && score1 === score2) JE++;
    });
    return JE;
  };

  const getJP = (team: string, games: GameType[]) => {
    let JP = 0;
    games.forEach((game) => {
      const { team1, score1, team2, score2 } = game;
      if (team1 === team && score1 < score2) JP++;
      if (team2 === team && score1 > score2) JP++;
    });
    return JP;
  };

  const getGF = (team: string, games: GameType[]) => {
    let GF = 0;
    games.forEach((game) => {
      const { team1, score1, team2, score2 } = game;
      if (team1 === team) GF = GF + score1;
      if (team2 === team) GF = GF + score2;
    });
    return GF;
  };

  const getGC = (team: string, games: GameType[]) => {
    let GC = 0;
    games.forEach((game) => {
      const { team1, score1, team2, score2 } = game;
      if (team1 === team) GC = GC + score2;
      if (team2 === team) GC = GC + score1;
    });
    return GC;
  };

  const parsedTo0 = (value: number): number => {
    return value === -1 ? 0 : value;
  };

  const getYellowCards = (team: string, games: GameType[]) => {
    let yellowCards = 0;
    games.forEach((game) => {
      const { team1, yellowCards1, team2, yellowCards2 } = game;
      if (team1 === team) yellowCards = yellowCards + parsedTo0(yellowCards1);
      if (team2 === team) yellowCards = yellowCards + parsedTo0(yellowCards2);
    });
    return yellowCards;
  };

  const getDoubleYellowCards = (team: string, games: GameType[]) => {
    let doubleYellowCards = 0;
    games.forEach((game) => {
      const { team1, doubleYellowCards1, team2, doubleYellowCards2 } = game;
      if (team1 === team)
        doubleYellowCards = doubleYellowCards + parsedTo0(doubleYellowCards1);
      if (team2 === team)
        doubleYellowCards = doubleYellowCards + parsedTo0(doubleYellowCards2);
    });
    return doubleYellowCards;
  };

  const getRedCards = (team: string, games: GameType[]) => {
    let redCards = 0;
    games.forEach((game) => {
      const { team1, redCards1, team2, redCards2 } = game;
      if (team1 === team) redCards = redCards + parsedTo0(redCards1);
      if (team2 === team) redCards = redCards + parsedTo0(redCards2);
    });
    return redCards;
  };

  const allGames = games.filter((game) => game.played);
  const allGamesRecords = groupCalculation(groupTeams, allGames);

  const lastGameId = (team: string): number => {
    return Math.max(
      ...games
        .filter((g) => g.team1 === team || g.team2 === team)
        .map((g) => g.id)
    );
  };

  //region getTiebreaker
  const getTiebreaker = (team: string, records: RecordGroup[]) => {
    const teamIndex = records.findIndex((r) => r.team === team);
    const nextTeam = records[teamIndex + 1]?.team;
    if (nextTeam) {
      const tiebreaker = tiebreakersTeams.filter(
        (tb) =>
          (tb.teamA === team && tb.teamB === nextTeam) ||
          (tb.teamA === nextTeam && tb.teamB === team)
      );
      if (tiebreaker.length) {
        const lastTiebreaker = tiebreaker[tiebreaker.length - 1];
        return RULES[lastTiebreaker?.rule]?.visibleId;
      }
    }
    return "";
  };

  //region groupQualification
  const groupQualification = (
    teams: string[],
    rulesGroup: RulesGroupType[],
    games: GameType[]
  ) => {
    QUALI_LOG &&
      console.group(
        "%c--->>> groupQualification <<<---",
        "color: yellow; font-weight: bold"
      );
    QUALI_LOG && console.log("%cteams", "color: cyan", teams);
    QUALI_LOG && console.log("%crulesGroup", "color: cyan", rulesGroup);

    let records: RecordGroup[] = [];
    const currentRules: RulesGroupType = rulesGroup[0];

    const teamsGames = games.filter(
      (game) =>
        game.played && teams.includes(game.team1) && teams.includes(game.team2) ///tmp
    );

    if (
      currentRules?.rulesIds[0] === RULES.FAIR_PLAY_UEFA.id ||
      currentRules?.rulesIds[0] === RULES.RED_CARDS.id ||
      currentRules?.rulesIds[0] === RULES.YELLOW_CARDS.id
    ) {
      tiebreakFairPlay.push(
        ...teams.filter((t) =>
          teamsGames.some((g) => g.team1 === t || g.team2 === t)
        )
      );
    }

    const allGames = games.filter((game) => game.played);
    QUALI_LOG && console.log("teamsGames", teamsGames);
    QUALI_LOG && console.log("allGames", allGames);
    QUALI_LOG &&
      console.log("tiedTeams", RULES[currentRules?.rulesIds[0]]?.tiedTeams);

    records = groupCalculation(
      teams,
      RULES[currentRules?.rulesIds[0]]?.tiedTeams ? teamsGames : allGames
    );

    // si no se ha jugado ningún partido no se hace nada (se mantiene orden inicial)
    if (!games.some((g) => g.played)) {
      QUALI_LOG && console.groupEnd();
      return records;
    }

    // si no hay más reglas a aplicar no se hace más nada
    if (rulesGroup.length === 0) {
      QUALI_LOG &&
        console.log("%cEmpatados completemente", "color: lightgreen", teams);
      QUALI_LOG && console.groupEnd();
      return records;
    }

    QUALI_LOG && console.log("records (clasificación)", records);

    //region compareRecordsByRules
    type CompareRecordsByRulesResult = {
      order: number;
      rule: string;
    };

    const compareRecordsByRules = (
      a: RecordGroup,
      b: RecordGroup
    ): CompareRecordsByRulesResult => {
      for (const rule of currentRules.rulesIds) {
        let ruleResult = applyRule(rule, a, b);
        if (rule === RULES.PENALTIES?.id) {
          // excepción -> PENALTIES se aplica si
          // 1. se enfrentan 2 equipos empatados en PTG, GF y GC
          // 2. son los únicos 2 equipos con esa cantidad de PTS
          // 3. el último partido de grupo es un empate entre esos 2 equipos
          if (
            records.length === 2 &&
            !allGamesRecords.some(
              (r) => r.team !== a.team && r.team !== b.team && r.PTS === a.PTS
            ) &&
            lastGameId(a.team) === lastGameId(b.team) &&
            games.find((g) => g.id === lastGameId(a.team))?.played
          ) {
          } else {
            ruleResult = 0;
          }
        }
        if (
          // excepción -> RANK_EU24 se aplica sólo si no involucra a la anfitriona Alemania
          rule === RULES.RANK_EU24?.id &&
          records.some((r) => r.team === ALL_TEAMS.GERMANY.id)
        ) {
          ruleResult = 0;
        }

        if (ruleResult !== 0) return { order: ruleResult, rule };
      }
      return { order: 0, rule: "n/a" };
    };

    //region order & ties
    const ties: [string, string][] = [];
    records.sort((a, b) => {
      const result = compareRecordsByRules(a, b).order;
      if (result === 0) ties.push([a.team, b.team]);
      return result;
    });
    QUALI_LOG && console.log("ties", ties);

    //region tiebreakersTeams
    for (let i = 0; i < records.length; i++) {
      for (let j = i + 1; j < records.length; j++) {
        const a = records[i];
        const b = records[j];
        const result = compareRecordsByRules(a, b);
        if (result.order !== 0) {
          tiebreakersTeams.push({
            teamA: a.team,
            teamB: b.team,
            rule: result.rule,
          });
        }
      }
    }

    //region sections
    const sections: RecordGroup[][] = [];
    let indice: number = 0;
    records.forEach((element, index) => {
      if (!sections[indice]) {
        sections.push([]);
        sections[indice].push(element);
      } else {
        if (
          ties.some(
            (tie) =>
              tie[0] === element.team && tie[1] === records[index - 1].team
          ) ||
          ties.some(
            (tie) =>
              tie[1] === element.team && tie[0] === records[index - 1].team
          )
        ) {
          sections[indice].push(element);
        } else {
          indice++;
          sections.push([]);
          sections[indice].push(element);
        }
      }
    });
    QUALI_LOG && console.log("sections", sections);
    tiebreakersSections.push({
      sections,
      tiebreakerRulesIds: currentRules.rulesIds,
    });

    const records2: RecordGroup[] = [];
    QUALI_LOG && console.log("%csections.forEach", "color: magenta");
    QUALI_LOG && console.log("teams", teams);
    sections.forEach((section, index) => {
      QUALI_LOG &&
        console.log(
          `%c> section ${index + 1}/${sections.length}`,
          "color: magenta"
        );
      QUALI_LOG && console.log("section", section);
      if (section.length === 1) {
        QUALI_LOG &&
          console.log(
            "%cPosición definida",
            "color: lightgreen",
            section[0].team,
            currentRules.rulesIds
          );
        records2.push(section[0]);
      } else {
        const newRules = structuredClone(rulesGroup);
        newRules.shift();
        if (
          teams.length === section.length &&
          teams.every((team) => section.some((s) => team === s.team))
        ) {
          QUALI_LOG &&
            console.log(
              "teams === section => han quedados empatados exactamente los mismos equipos de la llamada original, se avanza al siguiente ruleGroup"
            );
          const subQualification = groupQualification(teams, newRules, games);
          QUALI_LOG &&
            console.log(
              "%csubQualification",
              "color: orange",
              subQualification
            );
          records2.push(...subQualification);
        } else {
          QUALI_LOG &&
            console.log(
              "teams != section => la sección contiene equipos distintos a los de la llamada original"
            );
          QUALI_LOG &&
            console.log(
              currentRules.type === "simple"
                ? "ruleGroup 'simple' => se avanza al siguiente ruleGroup"
                : "ruleGroup 'recursive' se repite el mismo ruleGroup"
            );
          const rulesToApply =
            currentRules.type === "simple" ? newRules : rulesGroup;

          const subQualification = groupQualification(
            section.map((section) => section.team),
            rulesToApply,
            games
          );
          QUALI_LOG &&
            console.log(
              "%csubQualification",
              "color: orange",
              subQualification
            );
          QUALI_LOG && console.log("section", section);
          const subRecords: RecordGroup[] = [];
          subQualification.forEach((e) => {
            const allMatchesRecord = section.find((f) => f.team === e.team);
            if (allMatchesRecord) {
              subRecords.push(allMatchesRecord);
            } else {
              QUALI_LOG &&
                console.log(
                  "%cATENCIÓN, CASO EN TEORÍA IMPOSIBLE: Un team no se encuentra en la sección",
                  "color: red"
                );
            }
          });
          records2.push(...subRecords);
        }
      }
    });
    QUALI_LOG &&
      console.log("%crecords2 (clasificación final)", "color: red", records2);
    QUALI_LOG && console.groupEnd();
    QUALI_LOG &&
      console.log("%ctiebreakersTeams", "color: orange", tiebreakersTeams);
    QUALI_LOG &&
      console.log("%ctiebreakersSections", "color: red", tiebreakersSections);
    return records2;
  };

  //region applyRules
  const applyRule = (rule: string, a: RecordGroup, b: RecordGroup): number => {
    let AWAY_subGroup: RecordGroup[] = [];
    if (rule.includes("AWAY")) {
      AWAY_subGroup = groupCalculation(
        [a.team, b.team],
        allGames.filter((g) => g.team2 === a.team || g.team2 === b.team)
      );
    }

    let aRivals: string[] = [];
    let bRivals: string[] = [];
    if (rule.includes("RIVALS")) {
      aRivals = allGames
        .filter((g) => g.team1 === a.team || g.team2 === a.team)
        .map((g) => {
          if (g.team1 === a.team) return g.team2;
          else return g.team1;
        });
      bRivals = allGames
        .filter((g) => g.team1 === b.team || g.team2 === b.team)
        .map((g) => {
          if (g.team1 === b.team) return g.team2;
          else return g.team1;
        });
    }

    switch (rule) {
      case RULES.PTS.id:
      case RULES.PTS_VS_TIED_TEAMS.id:
        if (a.PTS !== b.PTS) return b.PTS - a.PTS;
        return 0;

      case RULES.DG.id:
      case RULES.DG_VS_TIED_TEAMS.id:
        if (a.DG !== b.DG) return b.DG - a.DG;
        return 0;

      case RULES.GF.id:
      case RULES.GF_VS_TIED_TEAMS.id:
        if (a.GF !== b.GF) return b.GF - a.GF;
        return 0;

      case RULES.RED_CARDS.id:
        const redCardsA = a.redCards + a.doubleYellowCards;
        const redCardsB = b.redCards + b.doubleYellowCards;
        if (redCardsA !== redCardsB) return redCardsA - redCardsB;
        return 0;

      case RULES.YELLOW_CARDS.id:
        if (a.yellowCards !== b.yellowCards)
          return a.yellowCards - b.yellowCards;
        return 0;

      case RULES.PENALTIES?.id:
        return Math.random() > 0.5 ? 1 : -1;

      case RULES.FAIR_PLAY_UEFA.id:
        const fairPlayPointsA =
          3 * parsedTo0(a.redCards) +
          3 * parsedTo0(a.doubleYellowCards) +
          1 * parsedTo0(a.yellowCards);
        const fairPlayPointsB =
          3 * parsedTo0(b.redCards) +
          3 * parsedTo0(b.doubleYellowCards) +
          1 * parsedTo0(b.yellowCards);
        if (fairPlayPointsA !== fairPlayPointsB)
          return fairPlayPointsA - fairPlayPointsB;
        return 0;

      case RULES.RANK_EU24.id:
        if (RANK_EU24[a.team] !== RANK_EU24[b.team])
          return RANK_EU24[b.team] - RANK_EU24[a.team];
        return 0;

      case RULES.WINS.id:
        if (a.JG !== b.JG) return b.JG - a.JG;
        return 0;

      case RULES.GF_AWAY.id:
        const a_GF_AWAY = AWAY_subGroup.find((r) => r.team === a.team)?.GF || 0;
        const b_GF_AWAY = AWAY_subGroup.find((r) => r.team === b.team)?.GF || 0;
        return b_GF_AWAY - a_GF_AWAY;

      case RULES.WINS_AWAY.id:
        const a_WINS_AWAY =
          AWAY_subGroup.find((r) => r.team === a.team)?.JG || 0;
        const b_WINS_AWAY =
          AWAY_subGroup.find((r) => r.team === b.team)?.JG || 0;
        return b_WINS_AWAY - a_WINS_AWAY;

      case RULES.PTS_RIVALS.id:
        let aRivalsPts = 0;
        aRivals.forEach((t) => {
          const record: RecordGroup[] = groupCalculation(
            [t],
            allGames.filter((g) => g.team1 === t || g.team2 === t)
          );
          aRivalsPts += record[0].PTS;
        });

        let bRivalsPts = 0;
        bRivals.forEach((t) => {
          const record: RecordGroup[] = groupCalculation(
            [t],
            allGames.filter((g) => g.team1 === t || g.team2 === t)
          );
          bRivalsPts += record[0].PTS;
        });

        return bRivalsPts - aRivalsPts;

      case RULES.DG_RIVALS.id:
        let aRivalsDG = 0;
        aRivals.forEach((t) => {
          const record: RecordGroup[] = groupCalculation(
            [t],
            allGames.filter((g) => g.team1 === t || g.team2 === t)
          );
          aRivalsDG += record[0].GF - record[0].GC;
        });

        let bRivalsDG = 0;
        bRivals.forEach((t) => {
          const record: RecordGroup[] = groupCalculation(
            [t],
            allGames.filter((g) => g.team1 === t || g.team2 === t)
          );
          bRivalsDG += record[0].GF - record[0].GC;
        });

        return bRivalsDG - aRivalsDG;

      case RULES.GF_RIVALS.id:
        let aRivalsGF = 0;
        aRivals.forEach((t) => {
          const record: RecordGroup[] = groupCalculation(
            [t],
            allGames.filter((g) => g.team1 === t || g.team2 === t)
          );
          aRivalsGF += record[0].GF;
        });

        let bRivalsGF = 0;
        bRivals.forEach((t) => {
          const record: RecordGroup[] = groupCalculation(
            [t],
            allGames.filter((g) => g.team1 === t || g.team2 === t)
          );
          bRivalsGF += record[0].GF;
        });

        return bRivalsGF - aRivalsGF;

      case RULES.RANK_UCL2425.id:
        if (RANK_UCL2425[a.team] !== RANK_UCL2425[b.team])
          return RANK_UCL2425[b.team] - RANK_UCL2425[a.team];
        return 0;

      case RULES.DRAW.id:
        const randomSort = Math.random() > 0.5 ? 1 : -1;
        return groupTeams.length > MAX_NUM_TEAMS_FOR_DRAW ? 1 : randomSort;

      default:
        // Orden alfabético
        // const aLabel = ALL_TEAMS[a.team].label;
        // const bLabel = ALL_TEAMS[b.team].label;
        // return aLabel.localeCompare(bLabel);
        return 0;
    }
  };

  type RANK_UCL2425Type = { [team: string]: number };
  const RANK_UCL2425: RANK_UCL2425Type = {
    [ALL_TEAMS.MCI?.id]: 148,
    [ALL_TEAMS.BAY?.id]: 144,
    [ALL_TEAMS.RMA?.id]: 136,
    [ALL_TEAMS.PSG?.id]: 116,
    [ALL_TEAMS.LIV?.id]: 114,
    [ALL_TEAMS.INT?.id]: 101,
    [ALL_TEAMS.DOR?.id]: 97 + 0.1, // 29 vs 18 en la 2023-24
    [ALL_TEAMS.LEI?.id]: 97,
    [ALL_TEAMS.FCB?.id]: 91,
    [ALL_TEAMS.LEV?.id]: 90,
    [ALL_TEAMS.ATM?.id]: 89,
    [ALL_TEAMS.ATA?.id]: 81,
    [ALL_TEAMS.JUV?.id]: 80,
    [ALL_TEAMS.BEN?.id]: 79,
    [ALL_TEAMS.ARS?.id]: 72,
    [ALL_TEAMS.BRU?.id]: 64,
    [ALL_TEAMS.SHA?.id]: 63,
    [ALL_TEAMS.MIL?.id]: 59,
    [ALL_TEAMS.FEY?.id]: 57,
    [ALL_TEAMS.SCP?.id]: 54.5,
    [ALL_TEAMS.PSV?.id]: 54,
    [ALL_TEAMS.DZA?.id]: 50 + 0.1, // 9 vs 7 en la 2023-24
    [ALL_TEAMS.SAL?.id]: 50,
    [ALL_TEAMS.LIL?.id]: 47,
    [ALL_TEAMS.EST?.id]: 40,
    [ALL_TEAMS.YBO?.id]: 34.5,
    [ALL_TEAMS.CEL?.id]: 32,
    [ALL_TEAMS.SLO?.id]: 30.5,
    [ALL_TEAMS.MON?.id]: 24,
    [ALL_TEAMS.SPA?.id]: 22.5,
    [ALL_TEAMS.AST?.id]: 20.86,
    [ALL_TEAMS.BOL?.id]: 18.056,
    [ALL_TEAMS.GIR?.id]: 17.897,
    [ALL_TEAMS.STU?.id]: 17.324,
    [ALL_TEAMS.STG?.id]: 14.5,
    [ALL_TEAMS.BRE?.id]: 13.366,
  };

  type RANK_EU24Type = { [team: string]: number };
  const RANK_EU24: RANK_EU24Type = {
    // 1ros de grupo
    [ALL_TEAMS.PORTUGAL?.id]: 4 * 24,
    [ALL_TEAMS.FRANCE?.id]: 4 * 22,
    [ALL_TEAMS.SPAIN?.id]: 4 * 21,
    [ALL_TEAMS.BELGIUM?.id]: 4 * 20.2,
    [ALL_TEAMS.ENGLAND?.id]: 4 * 20.1,
    [ALL_TEAMS.HUNGARY?.id]: 4 * 18,
    [ALL_TEAMS.TURKEY?.id]: 4 * 17,
    [ALL_TEAMS.ROMANIA?.id]: 4 * 16.2,
    [ALL_TEAMS.DENMARK?.id]: 4 * 16.1,
    [ALL_TEAMS.ALBANIA?.id]: 4 * 15,
    // 2dos de grupo
    [ALL_TEAMS.AUSTRIA?.id]: 3 * 19,
    [ALL_TEAMS.NETHERLANDS?.id]: 3 * 18,
    [ALL_TEAMS.SCOTLAND?.id]: 3 * 17,
    [ALL_TEAMS.CROATIA?.id]: 3 * 16.3,
    [ALL_TEAMS.SLOVENIA?.id]: 3 * 16.2,
    [ALL_TEAMS.SLOVAKIA?.id]: 3 * 16.1,
    [ALL_TEAMS.CZECH_REPUBLIC?.id]: 3 * 15,
    [ALL_TEAMS.ITALY?.id]: 3 * 14.2,
    [ALL_TEAMS.SERBIA?.id]: 3 * 14.1,
    [ALL_TEAMS.SWITZERLAND?.id]: 3 * 11,
    // play-offs
    // 3ros de grupo
    [ALL_TEAMS.UKRAINE?.id]: 2 * 14,
    [ALL_TEAMS.POLAND?.id]: 2 * 11,
    // 4tos de grupo
    [ALL_TEAMS.GEORGIA?.id]: 1 * 8,
  };

  //region renderGroup
  const renderGroup = () => {
    let recordsGroup: RecordGroup[] = [];

    const getGames = (): GameType[] => {
      const gamesX: GameType[] = [];
      data.COMPETITIONS_DEFINE.find(
        (cd: CompetitionDefineType) => cd.id === "euro_2024"
      )
        .groups.filter((g: any) => g.id !== "3")
        .forEach((grp: CompetitionGroupsDefineType) => {
          const storageData = localStorage.getItem(
            `/${competitionId}/${grp.id}`
          );
          const result = storageData
            ? JSON.parse(storageData)
            : ALL_GAMES.filter(
                (gm) =>
                  gm.competitionId === competitionId && gm.group === grp.id
              );
          gamesX.push(...result);
        });

      return gamesX;
    };

    if (groupId === "3") {
      const RULES3ROS = data.COMPETITIONS_DEFINE.find(
        (cd: CompetitionDefineType) => cd.id === "euro_2024"
      ).rules3ros;
      recordsGroup = groupQualification(terceros, RULES3ROS, getGames());
    } else recordsGroup = groupQualification(groupTeams, rules, games);

    const getColor = (index: number) => {
      for (const range of qualification) {
        const [start, end] = range.range;
        if (index + 1 >= start && index + 1 <= end) {
          return range.color;
        }
      }
    };

    return (
      <S.Table>
        <S.Thead>
          <S.Tr>
            {groupTeams.length >= MIN_NUM_TEAMS_FOR_NUMERATION && (
              <S.Th>#</S.Th>
            )}
            {GROUP_COLUMNS.map((c: string, index: number) => (
              <S.Th key={index} $column={c}>
                {c}
              </S.Th>
            ))}
          </S.Tr>
        </S.Thead>

        <S.Tbody>
          {recordsGroup.map((teamRecord: RecordGroup, index: number) => {
            const { team, JJ, JG, JE, JP, GF, GC, DG, PTS } = teamRecord;
            const teamRecordToRender = [team, JJ, JG, JE, JP, GF, GC, DG, PTS];

            return (
              <S.Tr key={index} color={getColor(index)}>
                {groupTeams.length >= MIN_NUM_TEAMS_FOR_NUMERATION && (
                  <S.Td>{index + 1}</S.Td>
                )}
                {teamRecordToRender.map((e: string | number, index) => (
                  <S.Td key={index}>
                    <Cell
                      value={e}
                      index={index}
                      tiebreak={getTiebreaker(team, recordsGroup)}
                    />
                  </S.Td>
                ))}
              </S.Tr>
            );
          })}
        </S.Tbody>
      </S.Table>
    );
  };

  //region handleChangeGame
  const handleChangeGame = (id: number, attr: string, value: number) => {
    const index = games.findIndex((game: GameType) => game.id === id);
    if (index === -1) return;
    const game = structuredClone(games[index]);
    game[attr] = value;
    game.played = game.score1 > -1 && game.score2 > -1;

    const newGames = structuredClone(games);
    newGames[index] = game;
    setGames(newGames);
  };

  const actuallyPlayed = (gameId: number): boolean => {
    const game = groupGames.find((g) => g.id === gameId);
    return game?.played || false;
  };

  const getTimezone = (): string => {
    const offset = new Date().getTimezoneOffset();
    const offsetSign = offset > 0 ? "-" : "+";
    const offsetHour = Math.abs(offset / 60);
    return `Horarios en hora local (UTC${offsetSign}${offsetHour})`;
  };

  //region renderGames
  const renderGames = () => {
    const jornadas: number[] = [];
    const compareNumbers = (a: number, b: number) => a - b;

    games.forEach((g) => {
      if (g.jornada && !jornadas.includes(g.jornada)) jornadas.push(g.jornada);
    });
    jornadas.sort(compareNumbers);

    return (
      <>
        <S.Games className="Games">
          {jornadas.length > 0
            ? jornadas.map((j) => (
                <Fragment key={j}>
                  <S.JornadaTitle>Jornada {j}</S.JornadaTitle>
                  <S.Jornada>
                    {games
                      .filter((g) => g.jornada === j)
                      .map((game) => (
                        <Game
                          key={game.id}
                          game={game}
                          actuallyPlayed={actuallyPlayed(game.id)}
                          handleChange={handleChangeGame}
                        />
                      ))}
                  </S.Jornada>
                </Fragment>
              ))
            : games.map((game) => (
                <Game
                  key={game.id}
                  game={game}
                  actuallyPlayed={actuallyPlayed(game.id)}
                  handleChange={handleChangeGame}
                />
              ))}
        </S.Games>
        <S.Timezone>{getTimezone()}</S.Timezone>
      </>
    );
  };

  //region generateDataPath
  const generateDataPath = () => {
    const someCards: boolean = games.some(
      (g) =>
        g.yellowCards1 > 0 ||
        g.yellowCards2 > 0 ||
        g.doubleYellowCards1 > 0 ||
        g.doubleYellowCards2 > 0 ||
        g.redCards1 > 0 ||
        g.redCards2 > 0
    );

    const typeDataSave: string = someCards ? "b" : "a";

    let result: string[] = [];

    games.forEach((e) => {
      const {
        id,
        score1,
        score2,
        yellowCards1,
        yellowCards2,
        doubleYellowCards1,
        doubleYellowCards2,
        redCards1,
        redCards2,
      } = e;
      const aDataType = `${id},${score1},${score2}`;
      const bDataType = `${parsedTo0(yellowCards1)},${parsedTo0(
        yellowCards2
      )},${parsedTo0(doubleYellowCards1)},${parsedTo0(
        doubleYellowCards2
      )},${parsedTo0(redCards1)},${parsedTo0(redCards2)}`;

      if (e.played) {
        result.push(aDataType);
        if (typeDataSave === "b") result.push(bDataType);
      }
    });

    const game0: GameType = games[0];
    const path = `/${game0.competitionId}/${game0.group}?${typeDataSave}=${result}`;
    return path;
  };

  const save = () => {
    try {
      localStorage.setItem(location.pathname, JSON.stringify(games));
      toast.success("Guardado estado actual");
    } catch (error) {
      toast.error("Error al guardar");
      console.error("Error al guardar", error);
    }
  };

  const share = async () => {
    const dataPath = generateDataPath();
    await saveToClipboard(`${REACT_APP_BASE_URL}${dataPath}`);
  };

  const saveToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Guardado en el portapapeles");
    } catch (error) {
      toast.error("Error al guardar en el portapapeles");
      console.log("Error al guardar en el portapapeles", error);
    }
  };

  const reset = () => {
    navigate(location.pathname);
    toast.success("De vuelta al estado guardado");
  };

  const resetToActual = () => {
    try {
      localStorage.removeItem(location.pathname);
      navigate(location.pathname);
      toast.success("De vuelta al estado actual");
    } catch (error) {
      toast.error("Error al volver al estado actual");
      console.error("Error al volver al estado actual", error);
    }
  };

  //region renderRules
  const renderRules = () => {
    let count = 0;
    let recursiveRules: number[] = [];
    return (
      <S.Rules $isOpenRules={isOpenRules}>
        <S.CloseButton onClick={() => setIsOpenRules(!isOpenRules)}>
          <FontAwesomeIcon icon={faCircleXmark} size="xl" />
        </S.CloseButton>
        <h3>Reglas de Clasificación</h3>
        {rules.map((rule, index) => {
          recursiveRules = [];
          return (
            <div key={index}>
              {rule.rulesIds.map((r: string, index: number) => {
                count++;
                if (rule.type === "recursive") recursiveRules.push(count);
                return (
                  <S.RuleDescription key={index}>
                    <p>{`${count}. ${RULES[r]?.visibleId}`}</p>
                    {RULES[r]?.description.split("\\n").map((row, index) => (
                      <p key={index}>{row}</p>
                    ))}
                  </S.RuleDescription>
                );
              })}
              {rule.type === "recursive" && (
                <p>{`Si, después de aplicar los criterios ${recursiveRules.map(
                  (rr) => rr
                )} los equipos aún tienen una clasificación igual, los criterios ${recursiveRules.map(
                  (rr) => rr
                )} se vuelven a aplicar exclusivamente a los partidos entre los equipos restantes para determinar sus clasificaciones finales. Si este procedimiento no lleva a una decisión, se continúa con la aplicación de los siguientes criterios`}</p>
              )}
            </div>
          );
        })}
      </S.Rules>
    );
  };

  //region return
  return (
    <S.GroupDetail className="Group">
      <S.Topbar>
        <S.CompetitionName>
          <p>{competition.name}</p>
          <FontAwesomeIcon
            icon={faRepeat}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenSidebar(true);
            }}
            size="lg"
          />
        </S.CompetitionName>
        <S.Buttons>
          {groupId !== "3" && (
            <>
              <Button
                type="save"
                tooltip="Guardar estado actual"
                onClick={save}
                disabled={!games.some((g) => g.played)}
              />
              <Button
                type="share"
                tooltip="Compartir estado actual"
                onClick={share}
                disabled={!games.some((g) => g.played)}
              />
              <Button
                type="reset"
                tooltip="Volver a estado guardado"
                onClick={reset}
              />
              <Button
                type="resetToActual"
                tooltip="Volver a estado actual de la competición"
                onClick={resetToActual}
              />
            </>
          )}
          <Button
            type="rules"
            tooltip="Ver reglas de la competición"
            onClick={() => setIsOpenRules(!isOpenRules)}
          />
        </S.Buttons>
      </S.Topbar>
      <GroupBar competition={competition} />
      {renderGroup()}
      {groupId !== "3" && renderGames()}
      {renderRules()}
    </S.GroupDetail>
  );
};
