import React, { useState } from "react";
import * as S from "./styles";
import { GameProps } from "types";
import { Badge } from "components/Badge";
import { useData } from "DataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

export const Game: React.FC<GameProps> = ({
  game,
  actuallyPlayed,
  handleChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useData();
  const {
    id,
    team1,
    score1,
    yellowCards1,
    doubleYellowCards1,
    redCards1,
    team2,
    score2,
    yellowCards2,
    doubleYellowCards2,
    redCards2,
  } = game;
  const TEAMS = data.TEAMS;

  const parseHandleChange = (
    attr: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = parseInt(e.target.value);
    if (isNaN(value)) {
      value = -1;
    } else {
      if (value < 0) value = 0;
      if (value > 99) value = 99;
    }
    handleChange(id, attr, value);
  };

  const NumberInput = (attr: string, value: number | undefined) => {
    return (
      <input
        id={`${id}_${attr}`}
        type="number"
        min={0}
        max={99}
        value={value === -1 ? "" : value}
        onChange={(e) => parseHandleChange(attr, e)}
      />
    );
  };

  const parsedDate = (datetime: Date | undefined): string => {
    if (!datetime) return "";

    const utcDate = new Date(datetime);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );

    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      day: "2-digit",
      month: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };

    let parsedDate = localDate
      .toLocaleString("es-ES", options)
      .replace(",", "")
      .replace(",", " -");

    parsedDate = parsedDate.charAt(0).toUpperCase() + parsedDate.slice(1);

    return parsedDate;
  };

  return (
    <S.Game>
      <S.Date>{parsedDate(game.date)}</S.Date>
      <S.Score
        id={game.id.toString()}
        className="Game"
        $actuallyPlayed={actuallyPlayed}
      >
        <div>{TEAMS[team1].label}</div>
        <div className="badge">
          <Badge url={TEAMS[team1].badge || ""} />
        </div>
        <div>{NumberInput("score1", score1)}</div>
        <div>
          <FontAwesomeIcon
            icon={isOpen ? faCaretUp : faCaretDown}
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
        <div>{NumberInput("score2", score2)}</div>
        <div className="badge">
          <Badge url={TEAMS[team2].badge || ""} />
        </div>
        <div>{TEAMS[team2].label}</div>
      </S.Score>
      {isOpen && (
        <>
          <S.GameCards className="Game" $actuallyPlayed={actuallyPlayed}>
            <div />
            <div>{NumberInput("yellowCards1", yellowCards1)}</div>
            <div className="badge">
              <Badge
                url={
                  "https://upload.wikimedia.org/wikipedia/commons/b/b1/Yellow_card.svg"
                }
              />
            </div>
            <div>{NumberInput("yellowCards2", yellowCards2)}</div>
            <div />
          </S.GameCards>
          <S.GameCards className="Game" $actuallyPlayed={actuallyPlayed}>
            <div />
            <div>{NumberInput("doubleYellowCards1", doubleYellowCards1)}</div>
            <div className="badge">
              <Badge
                url={
                  "https://upload.wikimedia.org/wikipedia/commons/8/86/Double_yellow_card.svg"
                }
              />
            </div>
            <div>{NumberInput("doubleYellowCards2", doubleYellowCards2)}</div>
            <div />
          </S.GameCards>
          <S.GameCards className="Game" $actuallyPlayed={actuallyPlayed}>
            <div />
            <div>{NumberInput("redCards1", redCards1)}</div>
            <div className="badge">
              <Badge
                url={
                  "https://upload.wikimedia.org/wikipedia/commons/e/e7/Red_card.svg"
                }
              />
            </div>
            <div>{NumberInput("redCards2", redCards2)}</div>
            <div />
          </S.GameCards>
        </>
      )}
    </S.Game>
  );
};
